$(function(){

  var id = $("#like_id").attr("data-id");
  var cnt = $("#like-cnt").attr("data-cnt");

  $(".like-btn-w").on("click","#like_id",function(){
    if($("#like_id").hasClass("like_click")) {
      cnt = Number(cnt) + 1;
      $("#like-cnt").text(cnt);

        $.ajax({
                    url: "/like/"+ id +"/index",
                    type: "get",
                    data: "params"
                });

        $("#like_id").removeClass("like_click");
        $("#like_id").addClass("l_clicked");

    } else if($("#like_id").hasClass("l_clicked")) {

      cnt -= 1;
      $("#like-cnt").text(cnt);

        $.ajax({
                    url: "/like/"+ id +"/like_cancel",
                    type: "get",
                    data: "params"
                });

        $("#like_id").removeClass("l_clicked");
        $("#like_id").addClass("like_click");

    }

  });

});
